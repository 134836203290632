<template>
    <div id="application-review-page">
        <Loading :active.sync="visible" :can-cancel="true" :is-full-page="fullPage" :on-cancel="onCancel"></Loading>

        <div class="card">
            <div class="card-header">
                <span>Application waiting for approval</span>
            </div>
            <div class="card-body">
                <div id="ag-grid-container">
                    <ag-grid-vue
                        style="width: 100%; height: 100%;"
                        class="ag-theme-alpine"
                        v-if="show_grid"
                        :gridOptions="grid_options"
                        :enableColResize="true"
                        :enableSorting="true"
                        :enableFilter="true"
                        :groupHeaders="true"
                        :toolPanelSuppressSideButtons="true"
                        :rowHeight="60"
                    ></ag-grid-vue>
                </div>
            </div>
        </div>

        <!-- Edit Modal -->
        <b-modal
            id="application-edit-modal"
            ref="modal"
            size="lg"
            title="Review Application"
            @ok="handleEdit"
            okTitle='Edit'
            scrollable
            >
            <form ref="form" @submit.stop.prevent="handleEditApplication">
                <b-form-group
                    label="Application Id"
                    label-for="id-input"
                >
                    <b-form-input
                        id="id-input"
                        v-model="selectedApplication.id"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Email"
                    label-for="email-input"
                >
                    <b-form-input
                        id="email-input"
                        v-model="selectedApplication.user_email"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Full Name"
                    label-for="fullname-input"
                >
                    <b-form-input
                        id="fullname-input"
                        v-model="selectedApplication.fullname"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <!-- <b-form-group
                    label="Date of Birth"
                    label-for="dateofbirth-input"
                >
                    <b-form-input
                        id="dateofbirth-input"
                        v-model="selectedApplication.dateofbirth"
                        readonly
                    ></b-form-input>
                </b-form-group> -->

                <b-form-group
                    label="Date of Birth"
                    label-for="dateofbirth-input"
                >
                    <b-form-datepicker 
                        id="dateofbirth-input" 
                        v-model="selectedApplication.dateofbirth"
                        readonly
                    ></b-form-datepicker>
                </b-form-group>

                <b-form-group
                    label="School Address"
                    label-for="schooladdress-input"
                >
                    <b-form-input
                        id="schooladdress-input"
                        v-model="selectedApplication.schooladdress"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Phone Number"
                    label-for="phonenumber-input"
                >
                    <b-form-input
                        id="phonenumber-input"
                        v-model="selectedApplication.phonenumber"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Family Branch"
                    label-for="familybranch-input"
                >
                    <b-form-input
                        id="familybranch-input"
                        v-model="selectedApplication.familybranch"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Name of Future School"
                    label-for="nameoffutureschool-input"
                >
                    <b-form-input
                        id="nameoffutureschool-input"
                        v-model="selectedApplication.nameoffutureschool"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Attended Last Academic Year"
                    label-for="attendedlastacademicyear-input"
                >
                    <b-form-input
                        id="attendedlastacademicyear-input"
                        v-model="selectedApplication.attendedlastacademicyear"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Future Grade Level"
                    label-for="futuregradelevel-input"
                >
                    <b-form-input
                        id="futuregradelevel-input"
                        v-model="selectedApplication.futuregradelevel"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Major"
                    label-for="major-input"
                >
                    <b-form-input
                        id="major-input"
                        v-model="selectedApplication.major"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Future Career Plan"
                    label-for="futurecareerplan-input"
                >
                    <b-form-input
                        id="futurecareerplan-input"
                        v-model="selectedApplication.futurecareerplan"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Estimated Cost"
                    label-for="estimatedcost-input"
                >
                    <b-form-input
                        id="estimatedcost-input"
                        v-model="selectedApplication.estimatedcost"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Approved Amount"
                    label-for="approvedamount-input"
                >
                    <b-form-input
                        id="approvedamount-input"
                        v-model="selectedApplication.approvedamount"
                        type="number"   
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        
        <!-- Send Email to Applicant -->
        <b-modal 
            id="application-notification-modal"
            title="Send Email"
            @ok="handleSendEmail"
            okTitle='Send'
        >
            <form ref="emailNotificationForm" @submit.stop.prevent="handleSendEmailNotification">
                <b-form-group
                    label="Subject"
                    label-for="subject-input"
                    hidden
                >
                    <b-form-input
                        id="subject-input"
                        name="subject"
                        value="Notification from Morgan Family Trust"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="To Email"
                    label-for="toemail-input"
                    hidden
                >
                    <b-form-input
                        id="toemail-input"
                        name="to_email"
                        v-model="selectedApplication.user_email"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="To Name"
                    label-for="fullname-input"
                >
                    <b-form-input
                        id="fullname-input"
                        name="to_name"
                        v-model="selectedApplication.fullname"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label-for="email-message-input"
                >
                    <b-form-textarea
                        id="email-message-input"
                        rows="3"
                        max-rows="8"
                        name="message"
                    ></b-form-textarea>
                </b-form-group>

                <b-form-group
                    label="Application body message"
                    label-for="application-body-input"
                    hidden
                >
                    <b-form-input
                        id="application-body-input"
                        name="application_body_message"
                        v-model="selectedApplication.application_notification_message"
                        readonly
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>


        <div id="overlay" v-if="showSendEmail">
            <div class="modal-dialog" modal-lg>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Send Email</h5>
                        <button type="button" class="close" @click="showSendEmail=false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> 
                    <div class="modal-body p-4">
                        <!-- <perfect-scrollbar @ps-scroll-y="onScroll" ref="scrollbar"> -->
                            <form @submit.prevent="sendEmail">
                                <label>To Name</label>
                                <input 
                                    type="text"
                                    readonly 
                                    v-model="currentUser.fullname"
                                    name="to_name"
                                    placeholder="To Name"
                                >

                                <label>Message</label>
                                <textarea 
                                    name="message"
                                    v-model="message"
                                    cols="30" rows="5"
                                    placeholder="Message">
                                </textarea>

                                <label>To Email</label>
                                <input 
                                    type="email" 
                                    readonly
                                    v-model="currentUser.user_email"
                                    name="to_email"
                                    placeholder="To Email"
                                >
                                
                                <input type="submit" value="Send">
                            </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete User Model -->
        <div id="overlay" v-if="showDeleteModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete User</h5>
                        <button type="button" class="close" @click="showDeleteModal=false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-4">
                        <h4 class="text-danger">Are you sure you want to delete this user?</h4>
                        <h5>You are deleting {{ currentUser.fullname }}</h5>
                        <hr>
                        <button class="btn btn-danger btn-lg" @click="showDeleteModal=false; deleteUser(); clearMsg();">Yes</button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button class="btn btn-success btn-lg" @click="showDeleteModal=false">No</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Approve application -->
        <b-modal 
                id="application-approval-modal"
                @ok="handleApproval"
                okTitle='Approve'
                >
                <template #modal-title>
                Approve Application
                </template>
                <div class="d-block text-center">
                    <h3>Are you sure you want to approve this application?</h3>
                </div>
                <form ref="approvalForm" @submit.stop.prevent="handleApproveApplication">
                    <b-form-group
                        label="To mail"
                        label-for="toemail-input"
                        hidden
                    >
                        <b-form-input
                            id="toemail-input"
                            name="to_email"
                            v-model="selectedApplication.user_email"
                            readonly
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Subject"
                        label-for="subject-input"
                        hidden
                    >
                        <b-form-input
                            id="subject-input"
                            name="subject"
                            value="Approved - Your Application Has Been Approved"
                            readonly
                        ></b-form-input>
                    </b-form-group> 
                    <b-form-group
                        label-for="approval-message-input"
                        hidden
                    >
                        <b-form-textarea
                            id="approval-message-input"
                            rows="3"
                            max-rows="12"
                            name="message"
                            v-model="selectedApplication.application_approval_message"
                        ></b-form-textarea>
                    </b-form-group>
                </form>
        </b-modal>

        <div id="overlay" v-if="showApproveApplication">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Approve Application</h5>
                        <button type="button" class="close" @click="showApproveApplication=false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-4">
                        <form @submit.prevent="sendApprovalEmail">
                            <h4 class="text-danger">Are you sure you want to approve this application?</h4>
                            <h5>You are approving {{ currentUser.fullname }}</h5>
                            <hr>
                            <input type="text" hidden name="to_name" v-model="currentUser.fullname">                                
                            <input type="email" hidden name="to_email" v-model="currentUser.user_email">
                            <template v-if="currentUser.isapproved1 === false && currentUser.isapproved2 === false">
                                <textarea hidden name="message">Your application is in approval phase</textarea>
                            </template>
                            <template v-else-if="currentUser.isapproved1 === true || currentUser.isapproved2 === true">
                                <textarea hidden name="message">Your application is approved</textarea>
                            </template>
                            
                            <!-- <input class="btn btn-danger btn-lg" type="submit" value="Approve"> -->
                            <button class="btn btn-success btn-lg">Yes</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>

        <!-- Deny Application Modal -->
        <!-- @ok="handleDeny"
        okTitle='Deny' -->
        <b-modal 
            id="application-deny-modal"
            title="Deny Application"
            @ok="handleDeny"
            okTitle='Deny'
            >
            <div class="d-block text-center">
                <h3>Are you sure you want to deny this application?</h3>
            </div>
            <form ref="denialForm" @submit.stop.prevent="handleDenyApplication">
                <b-form-group
                    label-for="denial-message-input"
                >
                    <b-form-textarea
                        id="denial-message-input"
                        placeholder="Your application is denied because..."
                        rows="3"
                        max-rows="8"
                        name="message"
                    ></b-form-textarea>
                </b-form-group>
                <b-form-group
                    label="Email"
                    label-for="email-input"
                    hidden
                >
                    <b-form-input
                        id="email-input"
                        name="user_email"
                        v-model="selectedApplication.user_email"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="To mail"
                    label-for="toemail-input"
                    hidden
                >
                    <b-form-input
                        id="toemail-input"
                        name="to_email"
                        v-model="selectedApplication.user_email"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Full Name"
                    label-for="fullname-input"
                    hidden
                >
                    <b-form-input
                        id="fullname-input"
                        name="to_name"
                        v-model="selectedApplication.fullname"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Application Id"
                    label-for="id-input"
                    hidden
                >
                    <b-form-input
                        id="id-input"
                        name="application_id"
                        v-model="selectedApplication.id"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Color"
                    label-for="color-input"
                    hidden
                >
                    <b-form-input
                        id="color-input"
                        name="color"
                        value="#DC3545"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Header Text"
                    label-for="header-text-input"
                    hidden
                >
                    <b-form-input
                        id="header-text-input"
                        name="header_text"
                        value="Application is Rejected"
                        readonly
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Application body message"
                    label-for="application-body-input"
                    hidden
                >
                    <b-form-input
                        id="application-body-input"
                        name="application_body_message"
                        v-model="selectedApplication.application_denial_message"
                        readonly
                    ></b-form-input>
                </b-form-group>


                <b-form-group
                    label="Subject"
                    label-for="subject-input"
                    hidden
                >
                    <b-form-input
                        id="subject-input"
                        name="subject"
                        value="REJECTED - Your Application Has Been Rejected"
                        readonly
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <div id="overlay" v-if="showDenyApplication">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Deny Application</h5>
                        <button type="button" class="close" @click="showDenyApplication=false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-4">
                        <form v-on:submit.prevent="sendDenialEmail">
                            <h4 class="text-danger">Are you sure you want to deny this application?</h4>
                            <h5>You are denying {{ currentUser.fullname }}</h5>
                            <hr>
                            <input type="text" hidden name="to_name" v-model="currentUser.fullname">                                
                            <input type="email" hidden name="to_email" v-model="currentUser.user_email">
                            <textarea name="message" placeholder="Your application is denied because..."></textarea>
                            <!-- <input class="btn btn-danger btn-lg" type="submit" value="Approve"> -->
                            <button class="btn btn-danger btn-lg">Yes</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
// import { ContactForm } from "@/components/Utils/ContactForm.vue";
import emailjs from 'emailjs-com';

import axios from 'axios';

import 'ag-grid-enterprise'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components: { PerfectScrollbar, AgGridVue, Loading  },
    data: function() {
        return {
            applications: null,
            loading: true,
            errored: false,
            errorMsg: "",
            successMsg: "",
            showEditModal: false,
            showDeleteModal: false,
            showApproveApplication: false,
            showDenyApplication: false,
            showSendEmail: false,
            users: [],
            newUser: {id: "", email: "", fullname: ""},
            currentUser: {},
            maxAmountCanBeApproved: 20000,

            //For message
            from_name: '',
            to_name: '',
            message: '',
            user_name: '',
            user_email: '',
            to_email: '',
            reply_to: '',

            selectedApplication: '',
            show_grid: false,

            visible: false,
            fullPage: true
        };
    },
    mounted: function() {
        // this.getAllApplications();
        // this.getAllApplications().then(function() {
        //     this.buildAgGrid();
        // });
        this.getAllApplications();
    },
    methods: {
        onCancel() {
            console.log('User cancelled the loader.')
        },
        onScroll(event) {
            console.log(this.$refs.scrollbar.ps, event);
        },
        getAllApplications() {
            this.visible = true;
            // this.show_grid = false;
            //console.log("Full name: " + `${this.$auth.user.name}` + ", email: " + `${this.$auth.user.email}`);
            axios
                .get(`${window.hostname}/applicationreview/${this.$auth.user.email}`)
                .then(res => {
                    if(res.data.error){
                        this.errorMsg = res.data.message;
                    }
                    else{
                        console.log("I am inside getAllApplications")
                        this.applications = res.data;
                        this.buildAgGrid();
                    }
                    // console.log(this.applications);
                    // this.applications = res.data;

                    // this.applications.forEach(element => {
                    //     console.log("Id: " + element.id + ", isapproved1: " + element.isapproved1 + ", isapproved2: " + element.isapproved2);
                    // });
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)

                this.visible = false;
        },
        buildAgGrid: function() {
            console.log("calling from buildAgGrid 1")
            this.show_grid = false;
            this.grid_options = {};
            this.grid_options.rowData = this.applications;
            this.grid_options.defaultColDef = { resizable: true };
            this.grid_options.columnDefs = this.createColumnDefs();
            
            var agGridHeight = window.innerHeight - 280;
            document.getElementById('ag-grid-container').setAttribute("style","height:" + agGridHeight + "px");

            console.log("calling from buildAgGrid 2")

            var self = this;
            this.grid_options.onGridReady = function() {
                self.grid_options.api.hideOverlay();
                self.grid_options.api.sizeColumnsToFit();
            }

            this.grid_options.getContextMenuItems = function() {
                return [
                    'copy',
                    'copyWithHeaders',
                    'paste',
                    'csvExport'
                ];
            };
            this.show_grid = true;
            console.log("calling from buildAgGrid 3")
        },
        createColumnDefs: function() {
            var columnDefs = [
                { 
                    headerName: 'ID', 
                    width: 150, 
                    filter: 'text', 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center'},
                    field: 'id',
                },
                { headerName: 'Email', field: 'user_email', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Full Name', field: 'fullname', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Date of Birth', field: 'dateofbirth', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'School Address', field: 'schooladdress', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Phone Number', field: 'phonenumber', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Family Branch', field: 'familybranch', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Name of Future School', field: 'nameoffutureschool', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Attended Last Academic Year', field: 'attendedlastacademicyear', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Future Grade Level', field: 'futuregradelevel', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Major', field: 'major', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Future Career Plan', field: 'futurecareerplan', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Estimated Cost', field: 'estimatedcost', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Approved Amount', field: 'approvedamount', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { 
                    headerName: 'Edit', 
                    width: 150, 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center', 'border': 'none'},
                    cellRenderer: function(params) {
                        var eElement = document.createElement('i');
                        eElement.className = "fas fa-edit"
                        eElement.style['cursor'] = "pointer";
                        eElement.style['color'] = "#000000";
                        eElement.addEventListener('click', function() {
                            console.log("I am here, params: ", params)
                            this.getSelectedApplicationInfo(params.data)
                            this.$bvModal.show('application-edit-modal')
                        }.bind(this));

                        return eElement;
                    }.bind(this)
                },
                { 
                    headerName: 'Approve', 
                    width: 150, 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center', 'border': 'none'},
                    cellRenderer: function(params) {
                        var eElement = document.createElement('i');
                        eElement.className = "fab fa-angellist"
                        eElement.style['cursor'] = "pointer";
                        eElement.style['color'] = "#000000";
                        eElement.addEventListener('click', function() {
                            console.log("I am here, params: ", params)
                            this.getSelectedApplicationInfo(params.data)
                            this.$bvModal.show('application-approval-modal')
                        }.bind(this));

                        return eElement;
                    }.bind(this)
                },
                { 
                    headerName: 'Deny', 
                    width: 150, 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center', 'border': 'none'},
                    cellRenderer: function(params) {
                        var eElement = document.createElement('i');
                        eElement.className = "fab fa-angellist"
                        eElement.style['cursor'] = "pointer";
                        eElement.style['color'] = "#000000";
                        eElement.addEventListener('click', function() {
                            console.log("I am here, params: ", params)
                            this.getSelectedApplicationInfo(params.data)
                            this.$bvModal.show('application-deny-modal')
                        }.bind(this));

                        return eElement;
                    }.bind(this)
                },
                { 
                    headerName: 'Send Email', 
                    width: 150, 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center', 'border': 'none'},
                    cellRenderer: function(params) {
                        var eElement = document.createElement('i');
                        eElement.className = "fab fa-angellist"
                        eElement.style['cursor'] = "pointer";
                        eElement.style['color'] = "#000000";
                        eElement.addEventListener('click', function() {
                            console.log("I am here, params: ", params)
                            this.getSelectedApplicationInfo(params.data)
                            this.$bvModal.show('application-notification-modal')
                        }.bind(this));

                        return eElement;
                    }.bind(this)
                }
            ]

            return columnDefs;
        },

        getSelectedApplicationInfo(selectedItem) {
            this.selectedApplication = selectedItem

            var applicationId_html = '<strong>' + selectedItem.id + '</strong>'
            this.selectedApplication['applicationId_html'] = applicationId_html
            console.log("calling from getSelectedApplicationInfo, selectedApplication: ", this.selectedApplication)


            // Application Denial Email
            var denialColor = '#DC3545'
            var denialHeaderText = 'Application is Rejected'
            var applicationDenialMessage = '<p>Hello '+ selectedItem.fullname +',</p>' +
                '<div style="background-color: ' + denialColor + '; width: 100%; height: 3em;"><span style="color: #ffffff; font-size: 18pt;' + 
                'display: block; margin-left: 0.5em; padding-top: 0.35em;">'+ denialHeaderText +'</span></div>' +
                '<p>Application Id <strong>'+ selectedItem.id +'</strong> has been rejected by Morgan Family Trust for the following reason(s):<br/><br/></p>';
            this.selectedApplication['application_denial_message'] = applicationDenialMessage
            
            // Application Approval Email
            var approvalColor = '#28A745'
            var approvalHeaderText = 'Application is Approved'
            var applicationApprovalMessage = '<p>Hello '+ selectedItem.fullname +',</p>' +
                '<div style="background-color: ' + approvalColor + '; width: 100%; height: 3em;"><span style="color: #ffffff; font-size: 18pt;' + 
                'display: block; margin-left: 0.5em; padding-top: 0.35em;">'+ approvalHeaderText +'</span></div>' +
                '<p>Application Id <strong>'+ selectedItem.id +'</strong> has been approved by Morgan Family Trust. <br /><br /></p>' +
                '<p>Best wishes,<br />Morgan Family Trust Team</p>';
            
            this.selectedApplication['application_approval_message'] = applicationApprovalMessage


            // Application Notification Email
            var notificationColor = '#0000FF'
            var notificationHeaderText = 'Notifcation on your application'
            var applicationNotificationMessage = '<p>Hello '+ selectedItem.fullname +',</p>' +
                '<div style="background-color: ' + notificationColor + '; width: 100%; height: 3em;"><span style="color: #ffffff; font-size: 18pt;' + 
                'display: block; margin-left: 0.5em; padding-top: 0.35em;">'+ notificationHeaderText +'</span></div>';
            
            this.selectedApplication['application_notification_message'] = applicationNotificationMessage
        },

        addUser(){
            //var formData = this.toFormData(this.newUser);
            axios
                .post(`${window.hostname}/application1`, {
                    email: this.newUser.email,
                    fullname: this.newUser.fullname
                })
                .then(res => {
                    this.newUser = {id: "", email: "", fullname: ""};
                    if(res.data.error){
                        this.errorMsg = res.data.message;
                    }
                    else{
                        this.successMsg = res.data.message;
                        this.getAllApplications();
                    }
                    console.log(res.data);
                    this.applications = res.data;
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },

        updateApprovedAmount(){
            axios
                .put(`${window.hostname}/application/${this.selectedApplication.id}`, {
                    approvedamount: this.selectedApplication.approvedamount
                })
                .then(res => {
                    console.log("calling from updateApprovedAmount, res: ", res)
                    if(res.data.error){
                        console.log("I am inside updateApprovedAmount 1")
                        this.errorMsg = res.data.message;
                    }
                    else {
                        console.log("I am inside updateApprovedAmount 2")
                        // this.successMsg = res.data.message;
                        // this.getAllApplications();
                        // for( var i = 0; i < this.applications.length; i++){ 
                        //     if ( this.applications[i]['id'] === this.selectedApplication.id) { 
                        //         this.applications[i]['approvedamount'] = this.selectedApplication.approvedamount; 
                        //     }
                        // }
                        // console.log("this.applications: ", this.applications)
                        this.grid_options.api.setRowData(this.applications)

                        // let selectedRow = this.grid_options.api.getSelectedRows()[0];
                        // let newRowData = this.rowData.filter(row => {
                        //     return row !== selectedRow;
                        // });
                        // this.rowData = newRowData;
                        
                        alert("Approval amount is updated successfully!")
                    }
                    // console.log(res.data);
                    // this.applications = res.data;
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },

        approveApplication(){
            //var formData = this.toFormData(this.currentUser);
            axios
                .put(`${window.hostname}/applicationreviewupdateapproval/${this.selectedApplication.id}`,{
                    email: this.$auth.user.email
                })
                .then(res => {
                    //this.currentUser = {id: "", email: "", fullname: ""};
                    
                    console.log("I am here!")
                    if(res.data.error){
                        this.errorMsg = res.data.message;
                    }
                    else {
                        this.successMsg = res.data.message;
                        this.getAllApplications();
                        this.grid_options.api.setRowData([])
                        this.grid_options.api.setRowData(this.applications)
                    }
                    // console.log(res.data);
                    // this.applications = res.data;
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },
        denyApplication(){
            //var formData = this.toFormData(this.currentUser);
            axios
                .put(`${window.hostname}/applicationreviewupdatedenial/${this.selectedApplication.id}`,{
                    email: this.$auth.user.email
                })
                .then(res => {
                    console.log("I am here!")
                    if(res.data.error){
                        this.errorMsg = res.data.message;
                    }
                    else{
                        this.successMsg = res.data.message;
                        this.getAllApplications();
                        this.grid_options.api.setRowData([])
                        this.grid_options.api.setRowData(this.applications)
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },
        deleteUser(){
            var formData = this.toFormData(this.currentUser);
            console.log("I am here!")
            axios
                .delete(`${window.hostname}/application1/${this.currentUser.id}`, formData)
                .then(res => {
                    this.currentUser = {};
                    if(res.data.error){
                        this.errorMsg = res.data.message;
                    }
                    else{
                        this.successMsg = res.data.message;
                        this.getAllApplications();
                    }
                    console.log(res.data);
                    this.applications = res.data;
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },
        toFormData(obj){
            var fd = new FormData();
            for(var i in obj){
                fd.append(i, obj[i]);
            }
            return fd;
        },
        selectUser(user){
            this.currentUser = user;
        },
        clearMsg(){
            this.errorMsg = "";
            this.successMsg = "";
        },
        sendEmail(e) {
            try {
                //console.log("name: " + this.fullname + ", email: " + this.to_email + ", message: " + this.message);
                emailjs.sendForm('service_l7jm7vp', 'template_2o4ak1l', e.target,
                'user_CX2bCnNNDblqp2GGhOGQ6', {
                    from_name: this.from_name,
                    to_name: this.to_name,
                    message: this.message,
                    user_name: this.user_name,
                    user_email: this.user_email,
                    to_email: this.to_email,
                    reply_to: this.reply_to
                })
                console.log('it works!!!')

            } catch (error) {
                console.log({error})
            }
            // Reset form field
            this.from_name = '',
            this.to_name = '',
            this.message = '',
            this.user_name = '',
            this.user_email = '',
            this.to_email = '',
            this.reply_to = ''
        },

        // previous good version
        // sendApprovalEmail(e) {
        //     try {
        //         console.log("name: " + this.from_name + ", email: " + this.to_email + ", message: " + this.message);
        //         this.message = "Hello"
        //         emailjs.sendForm('service_l7jm7vp', 'template_2o4ak1l', e.target,
        //         'user_CX2bCnNNDblqp2GGhOGQ6', {
        //             from_name: this.from_name,
        //             to_name: this.to_name,
        //             message: "approval in phase",
        //             user_name: this.user_name,
        //             user_email: this.user_email,
        //             to_email: this.to_email,
        //             reply_to: this.reply_to
        //         })
        //         this.showApproveApplication = false;

        //         this.approveApplication();
        //         this.clearMsg();
        //         console.log('approval works!!!')

        //     } catch (error) {
        //         console.log({error})
        //     }
        // },

        // version 1
        sendApprovalEmail() {
            try {
                emailjs.sendForm('service_l7jm7vp', 'template_nz173n8', this.$refs.approvalForm,
                'user_CX2bCnNNDblqp2GGhOGQ6')
                .then((result) => {
                    console.log('SUCCESS!', result.text)
                }, (error) => {
                    console.log('FAILED...', error.text)
                })
                this.showApproveApplication = false;

                // this.approveApplication();
                // this.clearMsg();
                console.log('approval works!!!')

            } catch (error) {
                console.log({error})
            }
        },

        // version 1
        sendDenialEmail() {
            try {
                console.log("selectedApplication: ", this.selectedApplication.fullname, ", email: ", this.selectedApplication.user_email)
                console.log("name: " + this.from_name + ", email: " + this.to_email + ", message: " + this.message);
                // var test = document.getElementById("application-deny-modal")
                // console.log("calling from sendDenialEmail, test: ", this.$refs.denialForm['application_id'])

                emailjs.sendForm('service_l7jm7vp', 'template_2o4ak1l', this.$refs.denialForm,
                'user_CX2bCnNNDblqp2GGhOGQ6').then((result) => {
                    console.log('SUCCESS!', result.text)
                }, (error) => {
                    console.log('FAILED...', error.text)
                })
                // this.showDenyApplication = false;

                // this.denyApplication();
                // this.clearMsg();
                console.log('denial works!!!')

            } catch (error) {
                console.log({error})
            }
        },

        sendNotificationEmail() {
            try {
                emailjs.sendForm('service_l7jm7vp', 'template_2o4ak1l', this.$refs.emailNotificationForm,
                'user_CX2bCnNNDblqp2GGhOGQ6').then((result) => {
                    console.log('SUCCESS!', result.text)
                }, (error) => {
                    console.log('FAILED...', error.text)
                })
                // this.showDenyApplication = false;

                // this.denyApplication();
                // this.clearMsg();
                console.log('notification email works!!!')

            } catch (error) {
                console.log({error})
            }
        },

        // version 2
        // sendDenialEmail() {
        //     try {
        //         subject = 'REJECTED - Your Application Has Been Rejected';
        //         console.log("selectedApplication: ", this.selectedApplication.fullname, ", email: ", this.selectedApplication.user_email)
        //         console.log("name: " + this.from_name + ", email: " + this.to_email + ", message: " + this.message);
        //         emailjs.sendForm('service_l7jm7vp', 'template_2o4ak1l', this.$refs.denialForm,
        //         'user_CX2bCnNNDblqp2GGhOGQ6', {
        //             subject: this.subject,
        //             message: this.message,
        //             color: this.color,
        //             header_text: this.header_text,
        //             application_id: this.application_id,
        //             to_email: this.to_email,
        //             reply_to: this.reply_to
        //         }).then((result) => {
        //             console.log('SUCCESS!', result.text)
        //         }, (error) => {
        //             console.log('FAILED...', error.text)
        //         })
        //         // this.showDenyApplication = false;

        //         // this.denyApplication();
        //         // this.clearMsg();
        //         console.log('denial works!!!')

        //     } catch (error) {
        //         console.log({error})
        //     }
        // },

        handleEdit(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleEditApplication()
        },
        handleEditApplication() {
            
            this.updateApprovedAmount()

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('application-edit-modal')
            })
        },

        handleApproval(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleApproveApplication()
        },
        handleApproveApplication() {

            console.log("Selected application: ", this.selectedApplication)

            this.approveApplication()

            if (this.selectedApplication.isapproved1 || this.selectedApplication.isapproved2) {
                console.log("I am inside handleApproveApplication, sendApprovalEmail");
                this.sendApprovalEmail();
            }

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('application-approval-modal')
            })
        },

        handleDeny(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleDenyApplication();
        },

        handleDenyApplication() {
            this.denyApplication();

            this.sendDenialEmail();


            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('application-deny-modal')
            })
        },

        handleSendEmail(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSendEmailNotification();
        },

        handleSendEmailNotification() {
            this.sendNotificationEmail();

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('application-notification-modal')
            })
        },
    },
    created: function() {
        axios
            .get(`${window.hostname}/application1`)
            .then(res => {
                console.log(res.data);
                this.applications = res.data;
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    }
}
</script>

<style lang="scss" scoped>
    #application-review-page {
        width: calc(100% - 4em);
        position: relative;
    }
    .card {
        position: relative;
        width: 100%;
        height: auto;
        border: #ffffff solid 1px;
        margin: 2em;
        background-color: hsla(0, 0%, 100%, 0.1);
        border: 0;
        border-radius: 7px;
        position: relative;
        margin-bottom: 24px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    }
    .card .card-header {
        text-transform: uppercase;
        font-size: 16pt;
        border-bottom: #ffffff solid 1px;
        height: 1.75em;
        padding-top: 0.25em;
        padding-left: 0.5em;
    }
    .card-header span {
        color: #ffffff;
    }
    .card .card-body {
        padding: 1em;
    }

    #overlay{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
    }
    .ps {
        height: 700px;
    }

    * {box-sizing: border-box;}

    label {
        float: left;
    }
    input[type=text], [type=email], textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical;
    }

    input[type=submit] {
        background-color: #4CAF50;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    input[type=submit]:hover {
        background-color: #b8c7b9;
    }

    .container {
        display: block;
        margin:auto;
        text-align: center;
        border-radius: 5px;
        background-color: #f2f2f2;
        padding: 20px;
        width: 50%;
    }

//    .form-group{
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         padding: 0 2%;
//         width: 100%;
//     }

</style>