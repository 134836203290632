import axios from "axios"

export default {
  async getAllApplicationStatus(userEmail, accessToken) {
    let res = axios.get(`${window.hostname}/applicationstatus/` + userEmail, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    console.log("Response data : " + (await res).data)
    return (await res).data;
  }
}