<template>
    <div id="application-form">
        <Loading :active.sync="visible" :can-cancel="true" :is-full-page="fullPage"></Loading>
        <b-card
            class="text-center"
            title="Application Form"
        >
        </b-card>
        <b-form @submit.prevent="submitApplication" @reset="onReset" v-if="show">
            <b-card bg-variant="light">
                <b-form-group
                label-cols-lg="3"
                label="Personal Information"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
                >
                    <b-form-group
                        label="Full Name:"
                        label-for="nested-full-name"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-full-name"
                            name="full_name"
                            v-model="$v.ApplicationFormData.full_name.$model"
                            aria-describedby="input-fullname-feedback"
                            placeholder="Enter your full name"
                            :state="validateState('full_name')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-fullname-feedback">
                            This is a required field and must be at least 5 characters.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Date of Birth:"
                        label-for="nested-dob"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-datepicker 
                            id="nested-dob"
                            name="date_of_birth" 
                            v-model="$v.ApplicationFormData.date_of_birth.$model" 
                            class="mb-2"
                            aria-describedby="input-dateofbirth-feedback"
                            :state="validateState('date_of_birth')"
                        ></b-form-datepicker>
                        <b-form-invalid-feedback id="input-dateofbirth-feedback">
                            This is a required field.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="School Address:"
                        label-for="nested-school-address"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-school-address"
                            name="school_address"
                            v-model="$v.ApplicationFormData.school_address.$model"
                            aria-describedby="input-schooladdress-feedback"
                            :state="validateState('school_address')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-dateofbirth-feedback">
                            This is a required field and must be at least 10 characters.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Phone Number:"
                        label-for="nested-phone-number"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-phone-number"
                            name="phone_number"
                            v-model="$v.ApplicationFormData.phone_number.$model" 
                            aria-describedby="input-phonenumber-feedback"
                            :state="validateState('phone_number')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-phonenumber-feedback">
                            Enter at least 10 numbers with/without hyphen(-)
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="U.S. Social Security Number(SSN):"
                        label-for="nested-ssn"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-ssn"
                            name="social_security_number"
                            v-model="$v.ApplicationFormData.social_security_number.$model" 
                            aria-describedby="input-ssn-feedback"
                            :state="validateState('social_security_number')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-ssn-feedback">
                            Enter at least 9 numbers with hyphen(-)
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Family Branch:"
                        label-cols-sm="3"
                        label-align-sm="right"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                    >
                        <b-form-radio-group
                            class="pt-2"
                            name="family_branch"
                            :options="['Morgan', 'Davis', 'Clark', 'Todd']"
                            :aria-describedby="ariaDescribedby"
                            :state="validateState('family_branch')"
                            v-model="$v.ApplicationFormData.family_branch.$model" 
                        ></b-form-radio-group>
                    </b-form-group>
                </b-form-group>
            </b-card>
            <b-card bg-variant="light">
                <b-form-group
                label-cols-lg="3"
                label="Academic and Career Information"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
                >
                    <b-form-group
                        label="Name of School you will be attending:"
                        label-for="nested-school-name"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-school-name"
                            name="name_of_future_school"
                            v-model="$v.ApplicationFormData.name_of_future_school.$model"
                            aria-describedby="input-nameofschool-feedback"
                            :state="validateState('name_of_future_school')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-nameofschool-feedback">
                            This is a required field and must be at least 10 characters.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Academic Year:"
                        label-for="nested-academic-year"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-academic-year"
                            name="academic_year"
                            v-model="$v.ApplicationFormData.academic_year.$model"
                            aria-describedby="input-academicyear-feedback"
                            :state="validateState('academic_year')"
                            type="number"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-academicyear-feedback">
                            This is a required field and must be between 1990 and 2100.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Did you attend the school last academic year?:"
                        label-cols-sm="3"
                        label-align-sm="right"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                    >
                        <b-form-radio-group
                            class="pt-2"
                            name="attended_last_academic_year"
                            :options="['Yes', 'No']"
                            :aria-describedby="ariaDescribedby"
                            v-model="$v.ApplicationFormData.attended_last_academic_year.$model" 
                            :state="validateState('attended_last_academic_year')"
                        ></b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                        label="Grade level for upcoming academic year:"
                        label-for="nested-grade-level"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-grade-level"
                            name="future_grade_level"
                            v-model="$v.ApplicationFormData.future_grade_level.$model"
                            aria-describedby="input-futuregradelevel-feedback"
                            :state="validateState('future_grade_level')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-futuregradelevel-feedback">
                            This is a required field and must be at least 5 characters.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Major:"
                        label-for="nested-major"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-major"
                            name="major"
                            v-model="$v.ApplicationFormData.major.$model" 
                            aria-describedby="input-major-feedback"
                            :state="validateState('major')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-major-feedback">
                            This is a required field and must be at least 5 characters.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Future Career Plan:"
                        label-for="nested-career-plan"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-career-plan"
                            name="future_career_plan"
                            v-model="$v.ApplicationFormData.future_career_plan.$model"
                            aria-describedby="input-futurecareerplan-feedback"
                            :state="validateState('future_career_plan')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-futurecareerplan-feedback">
                            This is a required field and must be at least 10 characters.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Estimated Cost In USD:"
                        label-for="nested-estimated-cost"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-input 
                            id="nested-estimated-cost"
                            name="estimated_cost"
                            v-model="$v.ApplicationFormData.estimated_cost.$model"
                            type="number"
                            aria-describedby="input-estimatedcost-feedback"
                            :state="validateState('estimated_cost')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-estimatedcost-feedback">
                            This is a required field and must be between $5 and $40000.
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-form-group>
            </b-card>
            <b-card id="apply-form-content">
                <b-button block type="submit" variant="primary" size="lg">Submit</b-button>
                <b-button block type="reset" variant="danger" size="lg">Reset</b-button>
            </b-card>
        </b-form>
    </div>
</template>

<script>
  import axios from 'axios'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  import ApplicationService from '@/services/ApplicationService.js';

  import { validationMixin } from "vuelidate";
  import { required, minLength, maxLength, between, helpers } from 'vuelidate/lib/validators'
  const phoneNumberValidator = helpers.regex('phoneNumberValidator', /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
  const ssnValidator = helpers.regex('ssnValidator', /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/)

  export default {
    mixins: [validationMixin],
    data() {
      return {
        fullname: '',
        ssn: '',
        dateOfBirth: '',
        form: {
          email: '',
          name: '',
          food: null,
          checked: []
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true,

        ApplicationFormData: {
            full_name: "",
            date_of_birth: "",
            school_address: "",
            phone_number: "",
            social_security_number: "",
            family_branch: "",
            name_of_future_school: "",
            academic_year: "",
            attended_last_academic_year: "",
            future_grade_level: "",
            major: "",
            future_career_plan: "",
            estimated_cost: ""
        },
        isSaving: false,

        visible: false,
        fullPage: true
      }
    },
    components: {
        Loading
    },
    validations: {
        ApplicationFormData: {
            full_name: {
                required,
                minLength: minLength(5)
            },
            date_of_birth: {
                required
            },
            school_address: {
                required,
                minLength: minLength(10)
            },
            phone_number: {
                required,
                phoneNumberValidator
            },
            social_security_number: {
                required,
                ssnValidator
            },
            family_branch: {
                required
            },
            name_of_future_school: {
                required,
                minLength: minLength(10)
            },
            academic_year: {
                required,
                between: between(1990, 2100)
            },
            attended_last_academic_year: {
                required
            },
            future_grade_level: {
                required,
                minLength: minLength(5)
            },
            major: {
                required,
                minLength: minLength(5)
            },
            future_career_plan: {
                required,
                minLength: minLength(10)
            },
            estimated_cost: {
                required,
                between: between(5, 40000)
            }
        }
    },
    mounted: function() {
        this.pageLoadProcessing();
    },
    methods: {
        pageLoadProcessing() {
            this.visible = true;
            // simulate AJAX
            setTimeout(() => {
                this.visible = false
            },2000)
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.ApplicationFormData[name];
            return $dirty ? !$error : null;
        },
        validateBeforeSubmit() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                // eslint-disable-next-line
                    alert('Form Submitted!');
                    return;
                }

                alert('Correct them errors!');
            });
        },
        onSubmit(event) {
            // console.log("I am here submitApplication")
            // event.preventDefault()
            // alert(JSON.stringify(this.ApplicationFormData))
            this.$v.ApplicationFormData.$touch();
            if (this.$v.ApplicationFormData.$anyError) {
                return;
            }

            alert("Form submitted!");
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.ApplicationFormData.full_name = ''
            this.ApplicationFormData.date_of_birth = ''
            this.ApplicationFormData.school_address = ''
            this.ApplicationFormData.phone_number = ''
            this.ApplicationFormData.social_security_number = ''
            this.ApplicationFormData.family_branch = ''
            this.ApplicationFormData.name_of_future_school = ''
            this.ApplicationFormData.academic_year = ''
            this.ApplicationFormData.attended_last_academic_year = ''
            this.ApplicationFormData.future_grade_level = ''
            this.ApplicationFormData.major = ''
            this.ApplicationFormData.future_career_plan = ''
            this.ApplicationFormData.estimated_cost = ''

            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
            this.show = true
            })
        },
        // async submitApplication (event) {
        //     this.$v.ApplicationFormData.$touch();
        //     if (this.$v.ApplicationFormData.$anyError) {
        //         return;
        //     }
            
        //     event.preventDefault()
        //     this.isSaving = true;
        //     try {
        //         // alert(JSON.stringify(this.ApplicationFormData))

        //         await axios.post(`${window.hostname}/apply/${this.$auth.user.email}`, this.ApplicationFormData)
        //             .then(res => {
        //                 alert('Your application is submitted successfully!');
        //                 if (res.status === 200) {
        //                     this.$router.push({ path : '/applicationstatus' });
        //                 }
        //             }
        //         );
        //     } catch (e) {
        //         alert(e.message)
        //     }
        //     this.isSaving = false
        // },
        async submitApplication (event) {
            this.$v.ApplicationFormData.$touch();
            if (this.$v.ApplicationFormData.$anyError) {
                return;
            }
            
            event.preventDefault()
            this.isSaving = true;


            // Get the access token from the auth wrapper
            const accessToken = await this.$auth.getTokenSilently()

            console.log("Access token: " + accessToken);

            // Use the eventService to call the getEventSingle method
            ApplicationService.submitApplications(this.$auth.user.email, this.ApplicationFormData, accessToken)
            .then(res => {
                // console.log("calling from application, res: ", res)
                alert('Your application is submitted successfully!');
                if (res.status === 200) {
                    this.$router.push({ path : '/applicationstatus' });
                }
            })
            .catch(error => {
                alert(error)
            })


            // try {
            //     // alert(JSON.stringify(this.ApplicationFormData))

            //     await axios.post(`${window.hostname}/apply/${this.$auth.user.email}`, this.ApplicationFormData)
            //         .then(res => {
            //             alert('Your application is submitted successfully!');
            //             if (res.status === 200) {
            //                 this.$router.push({ path : '/applicationstatus' });
            //             }
            //         }
            //     );
            // } catch (e) {
            //     alert(e.message)
            // }




            this.isSaving = false
        },
        ssnformatter(ssnvalue) {
            var modifiedSSNValue = ssnvalue
            var totalValidNumberCnt = 0;
            var collectAllValidNumbers = ""
            for (let index = 0; index < ssnvalue.length && totalValidNumberCnt < 9; index++) {
                const element = ssnvalue[index];
                if (element >= "1" && element <= "9") {
                    collectAllValidNumbers += element
                    totalValidNumberCnt++
                }
            }

            if (totalValidNumberCnt == 9 && collectAllValidNumbers.length == 9) {
                modifiedSSNValue = collectAllValidNumbers.slice(0,3) + "-" + collectAllValidNumbers.slice(3,5) + "-" + collectAllValidNumbers.slice(5,9)
            }
            return modifiedSSNValue
        },
        validateSSNString(ssnPart) {
            var totalValidNumberCnt = 0;
            for (let index = 0; index < ssnPart.length; index++) {
                const element = ssnPart[index];
                if (element >= "1" && element <= "9") {
                    totalValidNumberCnt++
                }
            }
            if (totalValidNumberCnt != ssnPart.length) {
                return false;
            }
            return true;
        }
    },
    computed: {
        fullNameState() {
            return this.ApplicationFormData.full_name.length > 4 ? true : false
        },
        ssnState() {
            var splittedSSN = this.ApplicationFormData.social_security_number.split("-")
            
            if (splittedSSN.length == 3) {
                // return splittedSSN[0].length == 3 && splittedSSN[1].length == 2 && splittedSSN[2].length == 4

                return (splittedSSN[0].length == 3 && this.validateSSNString(splittedSSN[0])) && (splittedSSN[1].length == 2 && this.validateSSNString(splittedSSN[1])) 
                    && (splittedSSN[2].length == 4 && this.validateSSNString(splittedSSN[2]))
            }
            return false
        }
    },
  }
</script>

<style scoped>
#application-form {
    width: calc(100% - 4em);
    position: relative;
}

.card {
  position: relative;
  width: 100%;
  height: auto;
  border: #ffffff solid 1px;
  margin: 2em;
  background-color: hsla(0, 0%, 100%, 0.1);
  border: 0;
  border-radius: 7px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
}

#requisition-form-fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

#apply-form-content {
  margin-bottom: 80px;
}
</style>