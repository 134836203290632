<template>
    <div id="application-status-page">
        
        <Loading :active.sync="visible" :can-cancel="true" :is-full-page="fullPage" :on-cancel="onCancel"></Loading>

        <div class="card">
            <div class="card-header">
                <span>Application Status</span>
            </div>
            <div class="card-body">
                <div id="ag-grid-container">
                    <ag-grid-vue
                        style="width: 100%; height: 100%;"
                        class="ag-theme-alpine"
                        v-if="show_grid"
                        :gridOptions="grid_options"
                        :enableColResize="true"
                        :enableSorting="true"
                        :enableFilter="true"
                        :groupHeaders="true"
                        :toolPanelSuppressSideButtons="true"
                        :rowHeight="60"
                    ></ag-grid-vue>
                </div>
            </div>

            <!-- Edit Application Modal -->
            <b-modal
                id="application-edit-modal"
                ref="modal"
                size="lg"
                title="Review Application"
                @ok="handleEdit"
                okTitle='Edit'
                scrollable
                >
                <form ref="form" @submit.stop.prevent="handleEditApplication">
                    <b-form-group
                        label="Application Id"
                        label-for="id-input"
                    >
                        <b-form-input
                            id="id-input"
                            v-model="selectedApplication.id"
                            readonly
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Email"
                        label-for="email-input"
                    >
                        <b-form-input
                            id="email-input"
                            v-model="selectedApplication.user_email"
                            readonly
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Full Name"
                        label-for="fullname-input"
                    >
                        <b-form-input
                            id="fullname-input"
                            v-model="selectedApplication.fullname"
                            :state="fullNameState"
                            aria-describedby="input-live-feedback"
                            required
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Enter at least 5 letters
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Date of Birth:"
                        label-for="dateofbirth-input"
                    >
                        <b-form-datepicker 
                            id="dateofbirth-input" 
                            v-model="selectedApplication.dateofbirth"
                        ></b-form-datepicker>
                    </b-form-group>

                    <b-form-group
                        label="School Address"
                        label-for="schooladdress-input"
                    >
                        <b-form-input
                            id="schooladdress-input"
                            v-model="selectedApplication.schooladdress"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Phone Number"
                        label-for="phonenumber-input"
                    >
                        <b-form-input
                            id="phonenumber-input"
                            v-model="selectedApplication.phonenumber"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="U.S. Social Security Number(SSN):"
                        label-for="ssn-input"
                    >
                        <b-form-input 
                            id="ssn-input"
                            v-model="selectedApplication.ssn" 
                            :state="ssnState"
                            :formatter="ssnformatter"
                            aria-describedby="input-live-feedback"
                            required
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                            Enter at least 9 numbers with/without hyphen(-)
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Family Branch"
                    >
                        <b-form-radio-group
                        :options="['Morgan', 'Davis', 'Clark', 'Todd']"
                        v-model="selectedApplication.familybranch" 
                        required
                        ></b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                        label="Name of Future School"
                        label-for="nameoffutureschool-input"
                    >
                        <b-form-input
                            id="nameoffutureschool-input"
                            v-model="selectedApplication.nameoffutureschool"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Academic Year:"
                        label-for="academicyear-input"
                    >
                        <b-form-input 
                            id="academicyear-input"
                            v-model="selectedApplication.academicyear" 
                            type="number"
                            min="1990"
                            max="2100"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Attended Last Academic Year"
                        label-for="attendedlastacademicyear-input"
                    >
                        <b-form-radio-group
                            id="attendedlastacademicyear-input"
                            :options="['Yes', 'No']"
                            v-model="selectedApplication.attendedlastacademicyear" 
                            required
                        ></b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                        label="Future Grade Level"
                        label-for="futuregradelevel-input"
                    >
                        <b-form-input
                            id="futuregradelevel-input"
                            v-model="selectedApplication.futuregradelevel"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Major"
                        label-for="major-input"
                    >
                        <b-form-input
                            id="major-input"
                            v-model="selectedApplication.major"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Future Career Plan"
                        label-for="futurecareerplan-input"
                    >
                        <b-form-input
                            id="futurecareerplan-input"
                            v-model="selectedApplication.futurecareerplan"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        label="Estimated Cost"
                        label-for="estimatedcost-input"
                    >
                        <b-form-input
                            id="estimatedcost-input"
                            v-model="selectedApplication.estimatedcost"
                        ></b-form-input>
                    </b-form-group>
                </form>
            </b-modal>

            <!-- Withdraw application -->
            <b-modal 
                id="application-withdrawal-modal"
                @ok="handleOk"
            >
                <template #modal-title>
                Withdraw Application
                </template>
                <div class="d-block text-center">
                <h3>Are you sure you want to withdraw this application?</h3>
                </div>
            </b-modal>

        </div>
    </div>
</template>

<script>
import ApplicationStatusService from '@/services/ApplicationStatusService.js';
import axios from 'axios';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
// import debounce from 'lodash.debounce';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    data: function() {
        return {
            applications: [],
            loading: true,
            errored: false,
            errorMsg: "",
            successMsg: "",
            showAddModal: false,
            showEditModal: false,
            showDeleteModal: false,
            users: [],
            newUser: {id: "", email: "", fullname: ""},
            currentUser: {},

            columnDefs: null,
            rowData: null,
            show_grid: false,
            list_of_forms: [],
            // selectedApplication: ''
            selectedApplication: {
                fullname: "",
                dateofbirth: "",
                schooladdress: "",
                phonenumber: "",
                ssn: "",
                familybranch: "",
                nameoffutureschool: "",
                academicyear: "",
                attendedlastacademicyear: "",
                futuregradelevel: "",
                major: "",
                futurecareerplan: "",
                estimatedcost: ""
            },

            visible: false,
            fullPage: true
        };
    },
    components: {
        AgGridVue,
        Loading
    },
    mounted: function() {
        this.getAllApplications();
    },
    methods: {
        onCancel() {
            console.log('User cancelled the loader.')
        },
        async getAllApplications() {
            this.visible = true;

            // Get the access token from the auth wrapper
            const accessToken = await this.$auth.getTokenSilently()

            console.log("Access token: " + accessToken);

            // Use the eventService to call the getEventSingle method
            ApplicationStatusService.getAllApplicationStatus(this.$auth.user.email, accessToken)
            .then(res => {
                this.applications = res;
                this.buildAgGrid();
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false);

            this.visible = false;
        },
        buildAgGrid: function() {
            this.show_grid = false;
            this.grid_options = {};
            this.grid_options.rowData = this.applications;
            this.grid_options.defaultColDef = { resizable: true };
            this.grid_options.columnDefs = this.createColumnDefs();
            
            var agGridHeight = window.innerHeight - 280;
            document.getElementById('ag-grid-container').setAttribute("style","height:" + agGridHeight + "px");

            var self = this;
            this.grid_options.onGridReady = function() {
            self.grid_options.api.hideOverlay();
            self.grid_options.api.sizeColumnsToFit();
            }

            this.grid_options.getContextMenuItems = function() {
                return [
                    'copy',
                    'copyWithHeaders',
                    'paste',
                    'csvExport'
                ];
            };
            this.show_grid = true;
        },
        createColumnDefs: function() {
            var columnDefs = [
                { 
                    headerName: 'Name', 
                    width: 150, 
                    filter: 'text', 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center'},
                    field: 'fullname',
                },
                { headerName: 'Future Grade Level', field: 'futuregradelevel', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Academic Year', field: 'academicyear', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { headerName: 'Application Status', field: 'application_status', width: 150, filter: 'text', cellStyle: {'padding-top': '8px', 'text-align': 'center'} },
                { 
                    headerName: 'Edit Application', 
                    width: 150, 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center', 'border': 'none'},
                    cellRenderer: function(params) {
                        console.log("calling from createColumnDefs, params: ", params)
                        var buttonElement = document.createElement('button')
                        buttonElement.style['cursor'] = "pointer";
                        buttonElement.style['color'] = "#000000";
                        buttonElement.style['width'] = "150px";
                        if (params.data.isapproved1 == true || params.data.isapproved2 == true) {
                            buttonElement.disabled = true;
                        }
                        else {
                            buttonElement.disabled = false;
                        }
                        
                        buttonElement.addEventListener('click', function() {
                            this.getSelectedApplicationInfo(params.data)
                            this.$bvModal.show('application-edit-modal')
                        }.bind(this));

                        var iconElement = document.createElement('i');
                        iconElement.className = "fas fa-edit"
                        buttonElement.appendChild(iconElement)


                        // icon start
                        // var eElement = document.createElement('i');
                        // eElement.className = "fas fa-edit"
                        // eElement.style['cursor'] = "pointer";
                        // eElement.style['color'] = "#000000";
                        // eElement.addEventListener('click', function() {
                        //     this.getSelectedApplicationInfo(params.data)
                        //     this.$bvModal.show('application-edit-modal')
                        // }.bind(this));
                        // icon end

                        // return eElement;

                        return buttonElement;
                    }.bind(this)
                },
                { 
                    headerName: 'Withdraw Application', 
                    width: 150, 
                    cellStyle: {'padding-top': '8px', 'text-align': 'center', 'border': 'none'},
                    cellRenderer: function(params) {
                        var eElement = document.createElement('i');
                        eElement.className = "fas fa-trash-alt text-danger"
                        eElement.style['cursor'] = "pointer";
                        eElement.style['color'] = "#000000";
                        eElement.addEventListener('click', function() {
                            this.getSelectedApplicationInfo(params.data)
                            this.$bvModal.show('application-withdrawal-modal')
                        }.bind(this));
                        return eElement;
                    }.bind(this)
                }
            ]

            return columnDefs;
        },
        deleteApplication(application_id){
            axios
                .delete(`${window.hostname}/application/${application_id}`)
                .then(res => {
                    if(res.data.error){
                        this.errorMsg = res.data.message;
                    }
                    else{
                        this.successMsg = res.data.message;
                        for( var i = 0; i < this.applications.length; i++){ 
                            if ( this.applications[i]['id'] === application_id) { 
                                this.applications.splice(i, 1); 
                            }
                        }
                        this.grid_options.api.setRowData(this.applications)
                    }
                })
                .catch(error => {
                    console.log(error)
                    alert("Application can not be withdrawn at this moment. Please contact system administrator!")
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },
        withdrawApplication(application_id){
            axios
                .put(`${window.hostname}/withdrawApplication/${application_id}`)
                .then(res => {
                    if(res.data.error){
                        this.errorMsg = res.data.message;
                    }
                    else{
                        this.successMsg = res.data.message;
                        for( var i = 0; i < this.applications.length; i++){ 
                            if ( this.applications[i]['id'] === application_id) { 
                                this.applications.splice(i, 1); 
                            }
                        }
                        this.grid_options.api.setRowData(this.applications)
                    }
                })
                .catch(error => {
                    console.log(error)
                    alert("Application can not be withdrawn at this moment. Please contact system administrator!")
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },
        getSelectedApplicationInfo(selectedItem) {
            console.log("calling from getSelectedApplicationInfo, selectedItem: ", selectedItem)
            this.selectedApplication = selectedItem
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            
            this.withdrawApplication(this.selectedApplication.id)

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('application-withdrawal-modal')
            })
        },
        ssnformatter(ssnvalue) {
            var modifiedSSNValue = ssnvalue
            var totalValidNumberCnt = 0;
            var collectAllValidNumbers = ""
            for (let index = 0; index < ssnvalue.length && totalValidNumberCnt < 9; index++) {
                const element = ssnvalue[index];
                if (element >= "1" && element <= "9") {
                    collectAllValidNumbers += element
                    totalValidNumberCnt++
                }
            }

            if (totalValidNumberCnt == 9 && collectAllValidNumbers.length == 9) {
                modifiedSSNValue = collectAllValidNumbers.slice(0,3) + "-" + collectAllValidNumbers.slice(3,5) + "-" + collectAllValidNumbers.slice(5,9)
            }
            return modifiedSSNValue
        },

        handleEdit(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleEditApplication()
        },
        handleEditApplication() {
            
            this.updateApplication()

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('application-edit-modal')
            })
        },

        updateApplication(){
            axios
                .put(`${window.hostname}/updateApplication/${this.selectedApplication.id}`, {
                    full_name: this.selectedApplication.fullname,
                    social_security_number: this.selectedApplication.ssn,
                    date_of_birth: this.selectedApplication.dateofbirth,
                    school_address: this.selectedApplication.schooladdress,
                    phone_number: this.selectedApplication.phonenumber,
                    family_branch: this.selectedApplication.familybranch,
                    name_of_future_school: this.selectedApplication.nameoffutureschool,
                    academic_year: this.selectedApplication.academicyear,
                    attended_last_academic_year: this.selectedApplication.attendedlastacademicyear,
                    future_grade_level: this.selectedApplication.futuregradelevel,
                    major: this.selectedApplication.major,
                    future_career_plan: this.selectedApplication.futurecareerplan,
                    estimated_cost: this.selectedApplication.estimatedcost,
                })
                .then(res => {
                    console.log("calling from updateApplication, res: ", res)
                    if(res.data.error){
                        console.log("I am inside updateApplication 1")
                    }
                    else {
                        console.log("I am inside updateApplication 2")
                        this.grid_options.api.setRowData(this.applications)
                        
                        alert("Application is updated successfully!")
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => this.loading = false)
        }
    },
    computed: {
        ssnState() {
            var splittedSSN = this.selectedApplication.ssn.split("-")
            if (splittedSSN.length == 3) {
                return splittedSSN[0].length == 3 && splittedSSN[1].length == 2 && splittedSSN[2].length == 4
            }
            return false
        },

        fullNameState() {
            return this.selectedApplication.fullname.length > 4 ? true : false
        },
    },
    created: function() {
        axios
            .get(`${window.hostname}/application1`)
            .then(res => {
                console.log(res.data);
                this.applications = res.data;
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    }
}
</script>

<style lang="scss" scoped>
    #application-status-page {
        width: calc(100% - 4em);
        position: relative;
    }
    .card {
        position: relative;
        width: 100%;
        height: auto;
        border: #ffffff solid 1px;
        margin: 2em;
        background-color: hsla(0, 0%, 100%, 0.1);
        border: 0;
        border-radius: 7px;
        position: relative;
        margin-bottom: 24px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    }
    .card .card-header {
        text-transform: uppercase;
        font-size: 16pt;
        border-bottom: #ffffff solid 1px;
        height: 1.75em;
        padding-top: 0.25em;
        padding-left: 0.5em;
    }
    .card-header span {
        color: #ffffff;
    }
    .card .card-body {
        padding: 1em;
    }
    #overlay{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
    }
</style>