<template>
    <div>
        <b-card
            class="text-center"
            title="Application Form"
        >
        <h2>Please <a href="/files/MFT Grant Application - 2023.doc">Download</a> the application form here.</h2>
        </b-card>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
#application-form {
    width: calc(100% - 4em);
    position: relative;
}

.card {
//   width: 100%;
//   height: auto;
//   margin: 2em;
//   position: relative;
//   margin-bottom: 24px;

  border: #ffffff solid 1px;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 7px;
  border: 0;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
}
</style>
