<template>
    <div>
        <h3>User</h3>
        <!-- <table class="table">
            <thead>
                <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">City</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" v-bind:key="user.id"> 
                <th scope="row">{{user.id}}</th>
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.address.city}}</td>
                </tr>
            </tbody>
        </table>  -->
        {{ $auth.user.email }}
        <!-- <ul v-for="user in users" v-bind:key="user.id">
            <li>{{user.name}} - {{user.email}} - {{user.address.city}}</li>
        </ul> -->
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'Users',
        data() {
            return {
                users: null,
            };
        },
        created: function() {
        axios
            .get('https://jsonplaceholder.typicode.com/users')
            .then(res => {
            this.users = res.data;
            })
        }
    }
</script>

<style lang="scss" scoped>
    h3 {
        margin-bottom: 5%;
    }
</style>