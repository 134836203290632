import axios from "axios"

export default {
  async submitApplications(userEmail, applicationFormData, accessToken) {
    // let res = axios.get(`${window.hostname}/applicationstatus/` + userEmail, {
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`
    //   }
    // });
    // console.log("Response data : " + (await res).data)
    // return (await res).data;

    console.log("userEmail: ", userEmail, ", applicationFormData: ", applicationFormData)

    let res = axios.post(`${window.hostname}/apply/` + userEmail, {
        data: applicationFormData,
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return (await res);
  }
}