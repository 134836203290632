<template>
  <div class="container">
    <h1>In Process</h1>
    <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        label="Family news:"
        label-for="input-1"
      >
        <b-form-textarea
            id="input-1"
            v-model="form.familyNews"
            placeholder="Enter family news..."
            rows="3"
            max-rows="6"
            required
        ></b-form-textarea>

      </b-form-group>

      <b-form-group id="input-group-2" label="College Grant Info:" label-for="input-2">
        <b-form-textarea
            id="input-2"
            v-model="form.collegeGrantInfo"
            placeholder="Enter college grant information..."
            rows="3"
            max-rows="6"
            required
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-3" label="Marriages:" label-for="input-3">
        <b-form-textarea
            id="input-3"
            v-model="form.marriages"
            placeholder="Enter marriages info..."
            rows="3"
            max-rows="6"
            required
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-4" label="Deaths:" label-for="input-4">
        <b-form-textarea
            id="input-4"
            v-model="form.deaths"
            placeholder="Enter Deaths info..."
            rows="3"
            max-rows="6"
            required
        ></b-form-textarea>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>

    </b-form> -->
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import axios from 'axios';
  export default {
    data() {
      return {
        form: {
          familyNews: '',
          collegeGrantInfo: '',
          marriages: ''
        },
        show: true
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()
        axios
          .post(`${window.hostname}/admin`, {
            familyNews: this.form.familyNews,
            collegeGrantInfo: this.form.collegeGrantInfo,
            marriages: this.form.marriages,
            deaths: this.form.deaths
          })
          .then(res => {  
              console.log(res.data);
              this.$router.push({ path : '/' });
          })
          .catch(error => {
            console.log(error)
          })
          //alert(JSON.stringify(this.form))
      },
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        this.form.familyNews = ''
        this.form.collegeGrantInfo = ''
        this.form.marriages = null,
        this.form.deaths = '',
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    created: function() {
      axios
          .post(`${window.hostname}/admin`, {
            familyNews: this.form.familyNews,
            collegeGrantInfo: this.form.collegeGrantInfo,
            marriages: this.form.marriages,
            deaths: this.form.deaths
          })
          .then(res => {
              console.log(res.data);
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
</script>